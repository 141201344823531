import * as React from "react";
import { graphql } from "gatsby";
import LatestInsights from "../components/LatestInsights/LatestInsights";
import Layout from "../components/Layout";
import { GatsbyImage } from "gatsby-plugin-image";
import "../styles/main.scss";

const NotFoundPage = ({ data: { strapiHomepage } }) => {
  const insightsData = {
    insights: strapiHomepage.insights.map((insight) => ({
      image: (
        <GatsbyImage
          image={insight.image.image.localFile.childImageSharp.gatsbyImageData}
          alt={insight.image.image.alternativeText}
        />
      ),
      shortDesc: insight.shortDesc,
      slug: insight.slug,
    })),
    latestInsightsHeading: strapiHomepage.Articles.articlesHeading,
  };

  return (
    <Layout seo={{ title: "404 Page" }}>
      <div className="page404-title">
        <h1>404 Not Found!</h1>
        <h3>
          It looks like you've taken a wrong turn. Would you like to go &gt;{" "}
          <a href="/">Home</a> &lt; ?
        </h3>
      </div>
      <LatestInsights insightsData={insightsData} />
    </Layout>
  );
};

export default NotFoundPage;

export const query = graphql`
  {
    strapiHomepage {
      Articles {
        articlesHeading
      }

      insights {
        shortDesc
        slug
        image {
          image {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  webpOptions: { quality: 100 }
                  placeholder: TRACED_SVG
                )
              }
            }
          }
        }
      }
    }
  }
`;
