import { navigate } from "gatsby";
import React from "react";
import { Button } from "../Button/Button";
import {
  latest_insights_container,
  latest_insights,
  latest_insight,
  button_container,
  seeAllButton,
} from "./LatestInsights.module.scss";

const LatestInsights = ({
  insightsData: { insights, latestInsightsHeading },
  seeAll = true,
}) => {
  return (
    <div className={latest_insights_container}>
      <h2>{latestInsightsHeading}</h2>
      <div className={latest_insights}>
        {insights.map((insight) => {
          return (
            <div
              onClick={() => navigate(`/articles/${insight.slug}`)}
              className={latest_insight}
            >
              {insight.image}
              <h4>{insight.shortDesc}</h4>
            </div>
          );
        })}
      </div>
      {seeAll ? (
        <div className={button_container}>
          <Button
            label="See all"
            type="internalLink"
            bkgColor="black"
            className={seeAllButton}
            toPage="/insights/latest-insights"
          />
        </div>
      ) : null}
    </div>
  );
};

export default LatestInsights;
